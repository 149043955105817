<template>
  <el-image
    v-if="src"
    :lazy="true"
    :style="styleObj"
    :src="src"
    :preview-src-list="list"
    :fit="fit"
  >
  </el-image>
  <i v-else class="iconfont icon-file-delete-fill"></i>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: [],
    },
    styleObj: {
      type: Object,
      default: {},
    },
    fit: {
      type: String,
      default: "fill",
    },
  },
};
</script>

<style lang="scss" scoped>
.el-image {
  margin-left: 50%;
  transform: translateX(-50%);
  ::v-deep img {
    max-height: inherit;
  }
}
.icon-file-delete-fill {
  margin-left: calc(50% - 14px);
  line-height: 48px;
  font-size: 35px;
  cursor: pointer;
}
</style>